<template>
  <div>
    <b-row class="match-height">
      <b-col md="3">
        <b-link to="/store-information">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon icon="SettingsIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Store Information')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
      <b-col md="3">
        <b-link to="/payment-services">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon icon="CreditCardIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Payment Services')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
      <b-col md="3">
        <b-link to="/shipping-services">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon icon="TruckIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Shipping Services')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
      <b-col md="3">
        <b-link to="/other-services">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon icon="GridIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Other Services')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
      <b-col md="3">
        <b-link to="/subscriptions">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon icon="GridIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Subscriptions')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
      <b-col md="3">
        <b-link to="/users-permissions">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon icon="UsersIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Users and Permissions')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
      <b-col md="3">
        <b-link to="/warehouses">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon icon="ArchiveIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Warehouses')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
      <b-col md="3">
        <b-link to="/shipping-zones">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <feather-icon icon="MapPinIcon" size="75" />
              </p>
              <h5 class="text-center">
                {{$t('Shipping Zones')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'Store-Configurations',
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>
